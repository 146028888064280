<template>
    <header class="base-header" :class="{'isNotFixed': isNotFixed}">
        <div class="base-header__logo"><img src="/images/main-logo.svg"></div>
        <div class="base-header__burger" v-if="!isNoBurger">
            <Burger></Burger>
        </div>
        <div class="base-header__email-cover" v-if="isAuthed">
            <template v-if="isCabinet">
                <div class="base-header__email-title">Ваша почта:</div>
                <div class="base-header__email-value">{{getUserMail}}</div>
            </template>
            <template v-else>
                <router-link to="/my" class="base-header__back-link">
                    Перейти в ЛК
                </router-link>
            </template>
        </div>
    </header>
</template>

<script>

import Burger from './Burger.vue';

export default {
  name: 'BaseHeader',
  props: {
    isNotFixed: String,
    isNoBurger: String,
  },
  components: { Burger },
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  computed: {
    getUserMail() {
      return this.$store.getters.getUserMail;
    },
    isCabinet() {
      return this.$route.name === 'Cabinet' || this.$route.name === 'CabinetFull';
    },
    isAuthed() {
      return this.$store.getters.getUserMail !== '';
    },
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/common/variables";

.base-header {
    height: 60px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    line-height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    z-index: 12;
    padding-left: 24px;
    box-sizing: border-box;
    max-width: $mobile-max;
    background: $white;

    @media only screen and (min-width: 768px) {
        left: auto;
    }

    &.isNotFixed {
        position: absolute;

        .base-header__burger {
            position: fixed;
            border: solid 1px $white;
            border-radius: 50%;
            box-shadow: 0 0 3px 1px rgba(255,255,255,0.5);

            @media only screen and (min-width: 768px) {
                left: 50%;
                margin-left: 327px;
            }
        }
    }

    &__back-link {
        color: $white;
        text-decoration: none;
        line-height: 37px;
        position: relative;
        padding-left: 30px;
        margin-left: -10px;

        &:before {
            position: absolute;
            background: url("/images/right-arrow.svg") no-repeat 50% 50%;
            width: 30px;
            height: 37px;
            display: inline-block;
            content: '';
            left: 0;
            top: 0;
        }
    }

    &__email-cover {
        position: absolute;
        left: 0;
        top: 100%;
        width: 100%;
        box-sizing: border-box;
        background: rgb($black, 0.83);
        line-height: 37px;
        padding: 0 24px;
        color: $white;
        font-size: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__title {
        font-weight: 600;
        font-size: 23px;
        line-height: 30px;
        margin-bottom: 10px;
    }

    &__logo {
        font-size: 20px;
        font-weight: 600;

        img {
            max-height: 24px;
            position: relative;
            top: 5px;
        }
    }

    &__burger {
        position: absolute;
        width: 32px;
        height: 32px;
        right: 24px;
        top: 14px;
        z-index: 3;
    }
}
</style>
