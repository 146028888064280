<template>
    <div class="cart-sale">
        <div class="es-dialog__title">
            Товар добавлен в корзину
        </div>

        <div class="es-dialog__text">
            Перейдите к оформлению заказа или вернитесь к
            покупкам, чтобы добавить еще один товар в корзину.
        </div>

        <button type="button"
                @click="closeDialog()"
                class="es-button red"
        >
            Назад
        </button>
        <button type="button"
                class="es-button"
                @click="showPaymentForm()"
        >
            Оформить заказ
        </button>
    </div>
</template>

<script>

export default {
  name: 'WebinarInvite',
  data() {
    return {
    };
  },
  computed: {
    infoForMeditationsInCart() {
      return this.$store.getters.infoForMeditationsInCart;
    },
    cartList() {
      return this.$store.state.meditations.cartList;
    },
  },
  mounted() {

  },
  methods: {
    closeDialog() {
      this.$store.commit('hideDialog');
    },
    showPaymentForm() {
      this.$store.commit('hideDialog');
      this.$store.commit('showPayment');

      this.cartList.forEach((id) => {
        this.$store.commit('markMeditationForPayByID', { id });
      });

      this.$store.commit('setCartMeditationPagePayment', true);
      this.$store.commit('setLandingPaymentFullPrice', this.infoForMeditationsInCart.price);
      this.$store.commit('setLandingPaymentPriceToPay', this.infoForMeditationsInCart.priceWithSale);
    },
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/dialog";

.cart-sale {
  margin: 20px 0 0;

    .es-dialog__text {
        margin-bottom: 30px;
        font-size: 14px;
        line-height: 24px;
    }

    &__sale-value {
        font-size: 19px;
        color: #E43131;
        margin-left: 5px;
    }
}
</style>
