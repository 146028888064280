<template>
    <div class="burger__cover"
         :class="{'isOpened': isOpened,
         'isLandingCover': isFullMenu,
         'isFixed': notFixed,
         'isLong': isFullCoursePage,
        }"
    >
        <div class="burger" @click="toggleBurger()">
            <div class="burger__line line-top"></div>
            <div class="burger__line line-middle"></div>
            <div class="burger__line line-bottom"></div>
        </div>
        <div class="burger__menu-cover-white"></div>
        <div class="burger__menu-cover"

        ></div>

            <div class="burger__menu">
                <div class="burger__menu-logo">
                    <img src="/images/main-logo-white.svg">
                </div>
                <div class="burger__menu-list">
                    <div class="burger__menu-item isTitle" v-if="!isMainPage"
                         @click="routeTo('insta')"
                    >
                        На главную
                    </div>

                    <div class="burger__menu-item firstLevel"
                         @click="routeTo('meditations')"
                    >
                        Медитации для женщин
                    </div>

                    <div class="burger__menu-item firstLevel"
                         @click="routeTo('consultation')"
                    >
                        Личная консультация
                    </div>

                    <!--<div class="burger__menu-item firstLevel"-->
                         <!--@click="routeTo('mnj-webinar')"-->
                         <!--v-if="isAuthed"-->
                    <!--&gt;-->
                        <!--Бесплатный вебинар-->
                    <!--</div>-->

                    <!--<div class="burger__menu-item firstLevel"-->
                         <!--@click="routeTo('mnj')"-->
                         <!--v-if="isAuthed"-->
                    <!--&gt;-->
                        <!--Курс для беременности-->
                    <!--</div>-->

                    <div class="burger__menu-second-level" v-if="isFullCoursePage && isAuthed">
                        <div class="burger__menu-item light"
                             @click="scrollToLandingPart('aboutSectionScrollMarker')"
                        >
                            Сколько нас
                        </div>
                        <div class="burger__menu-item light"
                             @click="scrollToLandingPart('audienceSectionScrollMarker')"
                        >
                            Для кого курс
                        </div>
                        <div class="burger__menu-item light"
                             @click="scrollToLandingPart('advantagesSectionScrollMarker')"
                        >
                            Что даст вам курс
                        </div>
                        <div class="burger__menu-item light"
                             @click="scrollToLandingPart('reviewSectionScrollMarker')"
                        >
                            Видео-отзывы
                        </div>
                        <div class="burger__menu-item light"
                             @click="scrollToLandingPart('structureSectionScrollMarker')"
                        >
                            Как устроен курс
                        </div>
                        <div class="burger__menu-item light"
                             @click="scrollToLandingPart('authorSectionScrollMarker')"
                        >
                            Автор и ведущая курса
                        </div>
                        <div class="burger__menu-item light"
                             @click="scrollToLandingPart('howItWorksSectionScrollMarker')"
                        >
                            Почему курс работает
                        </div>
                        <div class="burger__menu-item light"
                             @click="scrollToLandingPart('paymentSectionScrollMarker')"
                        >
                            Цена курса
                        </div>
                        <div class="burger__menu-item light"
                             @click="scrollToLandingPart('faqSectionScrollMarker')"
                        >
                            Вопрос-ответ
                        </div>
                    </div>

                    <router-link class="burger__menu-item isCabinet"
                                 to="/my"
                                 v-if="isAuthed && !isCabinet"
                    >
                        Кабинет
                    </router-link>

                    <div class="burger__menu-item isCabinet"
                         @click="logout('sdsfsdf')"
                         v-if="isAuthed && isCabinet"
                    >
                        Выйти из кабинета
                    </div>

                    <router-link class="burger__menu-item isCabinet"
                         to="/auth"
                         v-if="!isAuthed"
                    >
                        Войти в кабинет
                    </router-link>
                </div>
            </div>
    </div>
</template>

<script>

export default {
  name: 'Burger',
  props: {
    notFixed: String,
  },
  data() {
    return {
      complete: false,
      publicPath: process.env.BASE_URL,
    };
  },
  computed: {
    meditationsInfo() {
      return this.$store.getters.getMeditationsInfoForCourse;
    },
    getUserMail() {
      return this.$store.getters.getUserMail;
    },
    isOpened() {
      return this.$store.state.general.isBurgerOpened;
    },
    isFullMenu() {
      return this.meditationsInfo.priceForNoPayed !== 0;
    },
    isCabinet() {
      return this.$route.name === 'Cabinet' || this.$route.name === 'CabinetFull';
    },
    isSingleMeditation() {
      return this.$route.name === 'Finalprice';
    },
    isAuthed() {
      return this.$store.getters.getUserMail !== '';
    },
    isFullCoursePage() {
      return this.$route.name === 'Home'
        || this.$route.name === 'WelcomeWebinar36'
        || this.$route.name === 'WelcomeWebinar';
    },
    isMainPage() {
      return this.$route.name === 'Insta';
    },
  },
  methods: {
    logout() {
      localStorage.removeItem('userEmail');
      localStorage.removeItem('meditationAudio');
      window.location.replace('/');
    },
    routeTo(path) {
      this.$store.commit('hideBurger');
      this.$router.push({ path });
    },
    scrollToLandingPart(id) {
      if (this.$route.name !== 'WelcomeWebinar' && this.$route.name !== 'Home') {
        this.$router.push({ name: 'Home', params: { section: id } });
        this.$store.commit('hideBurger');

        return false;
      }

      const hiddenElement = document.querySelector(`#${id}`);

      this.$store.commit('hideBurger');
      hiddenElement.scrollIntoView({ block: 'start', behavior: 'smooth' });

      return true;
    },
    scrollToSingleLandingPart(id) {
      const hiddenElement = document.querySelector(`#${id}`);

      this.$store.commit('hideBurger');
      hiddenElement.scrollIntoView({ block: 'start', behavior: 'smooth' });
    },
    toggleBurger() {
      if (this.isOpened) {
        this.$store.commit('hideBurger');
      } else {
        this.$store.commit('showBurger');
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../assets/scss/common/variables";

.burger {
    width: 32px;
    height: 32px;
    background: $main-blue;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    z-index: 13;

    &__center-description {
        text-align: center;
        font-size: 18px;
        width: calc(100vw - 48px);
        padding-top: 60px;

        .es-button {
            max-width: 250px;
            margin: 30px auto 0 auto;
        }

        @media only screen and (min-width: 768px) {
           width: 700px;
        }
    }

    &__line {
        backface-visibility: hidden;
        position: absolute;
        left: 31%;
        border-top: 2px solid white;
        width: 38%;
        transition: all 0.55s, border-color 0ms;
    }

    .line-top {
        top: 11px;
    }
    .line-middle {
        top: 15px;
    }
    .line-bottom {
        top: 19px;
    }

    &__menu-cover-white {
        position: fixed;
        left: 0;
        top:0;
        background: $white;
        width: 100%;
        height: 100%;
        transition: all 0ms;
        transform-origin: 50% 0;
        transform: scale(0);
    }

    &__menu-list {
        max-height: calc(100vh - 120px);
        overflow-y: auto;

    }

    &__menu-cover {
        background: $main-blue;
        width: 200vw;
        height: 690px;
        border-radius: 50%;
        transition: transform 300ms, top 300ms;
        position: fixed;
        margin: -275px 0 0 calc(24px + 32px - 150vw);
        transform: scale(0);
        transform-origin: calc(100% - 20vw - 24px - 10px) calc(50% - 10px);

        /* ipad */
        @media only screen and (min-width: 768px) {
            width: 768px;
            left: 50%;
            margin: 0 0 0 -384px;
            border-radius: 0;
            top: -150vh;
            transform: scale(1);
            height: 450px;

            &:after {
                background: $main-blue;
                position: absolute;
                left:0;
                width: 100%;
                border-radius: 50%;
                height: 140px;
                bottom: -70px;
                content: '';
            }
        }
    }

    &__menu {
        position: fixed;
        left: 24px;
        top: 0;
        color: $white;
        z-index: 11;
        text-align: left;
        visibility: hidden;
        opacity: 0;
        transition: all 0ms;
        width: calc(100% - 40px);

        /* ipad */
        @media only screen and (min-width: 768px) {
            left: calc(50% - 350px);
            width: 710px;
        }
    }

    &__menu-logo {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 24px;
        line-height: 60px;

        img {
            max-height: 24px;
            position: relative;
            top: 5px;
        }
    }

    &__menu-item {
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        text-decoration: none;
        color: $white;
        display: block;
        margin-bottom: 24px;
        cursor: pointer;

        &.isTitle {
            margin: 0 0 28px 5px;
            padding-left: 28px;
            position: relative;

            &:before {
                background: url("/images/home-icon.svg");
                width: 14px;
                height: 14px;
                display: inline-block;
                content: '';
                position: absolute;
                top: 4px;
                left: 2px;
            }
        }

        &.firstLevel {
            position: relative;
        }

        &.light {
            font-size: 14px;
            font-weight: 500;
            margin: -10px 0 17px 5px;
            color: #E3ECFF;
            text-shadow: 1px 1px $main-blue;
        }

        &.isCabinet {
            text-shadow: 1px 1px $main-blue;
            margin-top: 45px;
            padding-left: 28px;
            position: relative;
        }

        &.isCabinet {
            &:before {
                background: url("/images/icon-cabinet.svg");
                width: 17px;
                height: 17px;
                display: inline-block;
                content: '';
                position: absolute;
                top: 3px;
                left: 0;
            }
        }
    }

    &__menu-second-level {
        padding-left: 40px;
        margin-bottom: 30px;
    }

    &__cover {
        &.isLandingCover {
            .burger__menu-cover {
                height: 600px;

                @media only screen and (min-width: 768px) {
                    height: 370px;
                }
            }

            .burger__menu-list.isBottom {
                margin-top: 40px;
            }

            .burger__menu-cover {
                width: 240vw;
                margin: -225px 0 0 calc(24px + 32px - 170vw);

                @media only screen and (min-width: 768px) {
                    width: 768px;
                    margin: 0 0 0 -384px;
                }
            }
        }

        &.isLong {
            .burger__menu-cover {
                height: 890px;

                @media only screen and (min-width: 768px) {
                    height: 640px;
                }
            }
        }

        &.isOpened {
            .burger {
                background: $white;
            }

            .burger__menu {
                visibility: visible;
                opacity: 1;
                transition: all 200ms 150ms;
            }

            .burger__menu-cover {
                transform: scale(1);

                @media only screen and (min-width: 768px) {
                    top: 0;
                }
            }

            .burger__menu-cover-white {
                transform: scale(1);
                transition: all 200ms 150ms;
            }

            .burger__line {
                border-top: 2px solid $main-blue;
            }

            .line-top {
                backface-visibility: hidden;
                top: 15px;
                transform: rotate(50deg);
                transition: 0.55s 0.5s;
            }
            .line-middle {
                opacity: 0;
            }
            .line-bottom {
                backface-visibility: hidden;
                top: 15px;
                transform: rotate(-410deg);
                transition: 0.55s 0.5s;
            }
        }
    }
}
</style>
