export default ({
  state: {
    currentTemplate: '',
    casesContent: '',
    isVisible: false,
  },
  mutations: {
    setCurrentTemplate(state, data) {
      state.currentTemplate = data;
    },
    showDialog(state) {
      state.isVisible = true;
    },
    hideDialog(state) {
      state.isVisible = false;
    },
    showDialogByType(state, data) {
      state.currentTemplate = data;
      state.isVisible = true;
    },
    setNewCasesContent(state, data) {
      state.casesContent = data;
    },
  },
  actions: {

  },
  modules: {
  },
  getters: {

  },
});
