import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/cards',
    name: 'Cards',
    meta: { title: 'Карты' },
    component: () => import(/* webpackChunkName: "my" */ '../views/CardsPage.vue'),
  },
  {
    path: '/marathon',
    name: 'Marathon',
    meta: { title: 'Бесплатный марафон “5 дней на пути к материнству”' },
    component: () => import(/* webpackChunkName: "my" */ '../views/Marathon.vue'),
  },
  {
    path: '/healing_marathon',
    name: 'MarathonHealingShort',
    meta: { title: 'Бесплатный марафон «Здоровье, отношения, успех»' },
    component: () => import(/* webpackChunkName: "my" */ '../views/MarathonHelth.vue'),
  },
  {
    path: '/healing_marathon',
    name: 'MarathonHealingShort',
    meta: { title: 'Бесплатный марафон «Здоровье, отношения, успех»' },
    component: () => import(/* webpackChunkName: "my" */ '../views/MarathonHelth.vue'),
  },
  {
    path: '/healing_marathon_old',
    name: 'MarathonHealing',
    meta: { title: 'Бесплатный марафон «Здоровье, отношения, успех»' },
    component: () => import(/* webpackChunkName: "my" */ '../views/MarathonHelth.vue'),
  },
  {
    path: '/healing_marathon_thanks',
    name: 'MarathonThanksHealth',
    meta: { title: 'Бесплатный марафон «Здоровье, отношения, успех»' },
    component: () => import(/* webpackChunkName: "my" */ '../views/MarathonHelth.vue'),
  },
  {
    path: '/marathon',
    name: 'Marathon',
    meta: { title: 'Бесплатный марафон “5 дней на пути к материнству”' },
    component: () => import(/* webpackChunkName: "my" */ '../views/Marathon.vue'),
  },
  {
    path: '/marathon_thanks',
    name: 'MarathonThanks',
    meta: { title: 'Бесплатный марафон “5 дней на пути к материнству”' },
    component: () => import(/* webpackChunkName: "my" */ '../views/Marathon.vue'),
  },
  {
    path: '/',
    name: 'StartInsta',
    meta: { title: 'Сайт психолога Елены Сягло' },
    component: () => import(/* webpackChunkName: "my" */ '../views/InstaStart.vue'),
  },
  {
    path: '/thanks',
    name: 'SayThanks',
    meta: { title: 'Сайт психолога Елены Сягло' },
    component: () => import(/* webpackChunkName: "my" */ '../views/SayThanks.vue'),
  },
  {
    path: '/club_marathon',
    name: 'ClubMarathonShort',
    meta: { title: 'Бесплатный марафон “5 дней на пути к материнству”' },
    component: () => import(/* webpackChunkName: "my" */ '../views/ClubMarathon.vue'),
  },
  {
    path: '/club',
    name: 'ClubMarathonFull',
    meta: { title: 'Бесплатный марафон “5 дней на пути к материнству”' },
    component: () => import(/* webpackChunkName: "my" */ '../views/ClubMarathon.vue'),
  },
  {
    path: '/club_bf',
    name: 'ClubMarathonBF',
    meta: { title: 'Бесплатный марафон “5 дней на пути к материнству”' },
    component: () => import(/* webpackChunkName: "my" */ '../views/ClubMarathon.vue'),
  },
  {
    path: '/guidebook',
    name: 'Guidebook',
    meta: { title: 'Бесплатный марафон “5 дней на пути к материнству”' },
    component: () => import(/* webpackChunkName: "my" */ '../views/Guidebook.vue'),
  },
  {
    path: '/cardsMetaphorical',
    name: 'CardsSmall',
    meta: { title: 'Карты' },
    component: () => import(/* webpackChunkName: "my" */ '../views/CardsPageSmall.vue'),
  },
  {
    path: '/welcomeVideo',
    name: 'WelcomeVideo',
    meta: { title: 'Курс для женщин «Медитация в новую жизнь»' },
    component: () => import(/* webpackChunkName: "my" */ '../views/WebinarSuccess.vue'),
  },
  {
    path: '/start-koabmhuss',
    meta: { title: 'От бесплодия к счастливому материнству.' },
    name: 'WebinarInstructionsEU',
    component: () => import(/* webpackChunkName: "my" */ '../views/WebinarInstructions.vue'),
  },
  {
    path: '/start-kowog6sx20',
    meta: { title: 'От бесплодия к счастливому материнству.' },
    name: 'WebinarInstructionsAsia',
    component: () => import(/* webpackChunkName: "my" */ '../views/WebinarInstructions.vue'),
  },
  /* Лендинг для страницы продажи участия в зум конфернции */
  { path: '/flow1', redirect: '/flow2' },
  { path: '/courseland3', redirect: '/courseland4' },
  { path: '/flow2', redirect: '/flow3' },
  { path: '/flow3', redirect: '/webinar4' },
  { path: '/webinar4', redirect: '/webinar4-taweh55w' },
  {
    path: '/flow4',
    name: 'WebinarLanding',
    meta: {
      title: 'Четвертый поток исцеления',
      description: 'Это место быстрых трансформаций',
    },
    component: () => import(/* webpackChunkName: "my" */ '../views/WebinarLanding.vue'),
  },
  {
    path: '/practice1',
    name: 'TelegramMeeting',
    meta: {
      title: 'Групповая медитация на гармонизацию вашего состояния на энергетическом и физическом ровне',
      description: 'Я проведу для вас медитацию в прямом эфире в закрытом телеграм канале. '
      + 'Будем с вами в одном поле, получите мою поддержку, зарядитесь моим состоянием',
    },
    component: () => import(/* webpackChunkName: "my" */ '../views/TelegramMeeting.vue'),
  },
  {
    path: '/practiceSuccess',
    name: 'TelegramFlowSuccess',
    meta: {
      title: 'Групповая медитация на гармонизацию вашего состояния на энергетическом и физическом ровне',
      description: 'Я проведу для вас медитацию в прямом эфире в закрытом телеграм канале. '
      + 'Будем с вами в одном поле, получите мою поддержку, зарядитесь моим состоянием',
    },
    component: () => import(/* webpackChunkName: "my" */ '../views/TelegramPaymentSuccess.vue'),
  },
  {
    path: '/flowSuccess',
    name: 'WebinarFlowSuccess',
    meta: {
      title: 'Четвертый поток исцеления',
      description: 'Это место быстрых трансформаций',
    },
    component: () => import(/* webpackChunkName: "my" */ '../views/WebinarPaymentSuccess.vue'),
  },
  // {
  //   path: '/flow1-taweh42w',
  //   name: 'Flow1-record-repeat',
  //   meta: {
  //     title: 'Поток исцеления',
  //     description: 'Волшебное пространство, где происходят массовые метаморфозы сознания.',
  //   },
  //   component: () => import(/* webpackChunkName: "my" */ '../views/WebinarPaymentSuccess.vue'),
  // },
  {
    path: '/webinar4-taweh55w',
    name: 'Flow1-record',
    meta: {
      title: 'Поток исцеления',
      description: 'Волшебное пространство, где происходят массовые метаморфозы сознания.',
    },
    component: () => import(/* webpackChunkName: "my" */ '../views/WebinarPaymentSuccess.vue'),
  },
  {
    path: '/summary',
    name: 'Summary',
    meta: { title: 'Курс для женщин «Медитация в новую жизнь»' },
    component: () => import(/* webpackChunkName: "my" */ '../views/Summary.vue'),
  },
  {
    path: '/mnj-webvisitors-15',
    name: 'free-webinar-transit-page-15',
    meta: { title: 'Курс для женщин «Медитация в новую жизнь»' },
    component: () => import(/* webpackChunkName: "my" */ '../views/FreeWebinar.vue'),
  },
  {
    path: '/mnj-webvisitors-19',
    name: 'free-webinar-transit-page-19',
    meta: { title: 'Курс для женщин «Медитация в новую жизнь»' },
    component: () => import(/* webpackChunkName: "my" */ '../views/FreeWebinar.vue'),
  },
  /* Лендинг для покупки новых медитаций, которые не попкали в пакет */
  {
    path: '/new',
    name: 'New',
    meta: {
      title: 'Медитации для женщин',
    },
    component: () => import(/* webpackChunkName: "my" */ '../views/FinalPrice.vue'),
  },
  /* Лендинг для покупки отдлеьных медитаций с каунторм на 3 дня */
  {
    path: '/meditations',
    name: 'Finalprice',
    meta: {
      title: 'Медитации для женщин',
      description: 'Послушайте короткий фрагмент медитации бесплатно',
    },
    component: () => import(/* webpackChunkName: "my" */ '../views/FinalPrice.vue'),
  },
  /* Делаем редирект со старого урла */
  { path: '/new', redirect: '/meditations' },
  { path: '/mnj-top', redirect: '/meditations' },
  { path: '/finalprice', redirect: '/meditations' },
  { path: '/insta', redirect: '/' },
  {
    path: '/consultation',
    meta: {
      title: 'Сайт психолога Елены Сягло',
      description: 'Сайт психолога Елены Сягло',
    },
    name: 'ConsultatantList',
    component: () => import(/* webpackChunkName: "my" */ '../views/ConsultantListPage.vue'),
  },
  {
    path: '/courseLanding',
    name: 'CourseLanding',
    meta: {
      title: 'Авторский курс Елены Сягло «Ключ к материнству»',
      description: 'Авторский курс Елены Сягло «Ключ к материнству»',
    },
    component: () => import(/* webpackChunkName: "my" */ '../views/CourseLandingPage.vue'),
  },
  {
    path: '/courseland4',
    name: 'Courseland20',
    meta: {
      title: 'Авторский курс Елены Сягло «Ключ к материнству»',
      description: 'Авторский курс Елены Сягло «Ключ к материнству»',
    },
    component: () => import(/* webpackChunkName: "my" */ '../views/CourseLandingPage20.vue'),
  },
  {
    path: '/courseland20short',
    name: 'Courseland20Short',
    meta: {
      title: 'Авторский курс Елены Сягло «Ключ к материнству»',
      description: 'Авторский курс Елены Сягло «Ключ к материнству»',
    },
    component: () => import(/* webpackChunkName: "my" */ '../views/CourseLandingPage20.vue'),
  },
  {
    path: '/consultationPayment',
    name: 'ConsultatantPayment',
    meta: {
      title: 'Страница для оплаты консультаций',
      description: 'Страница для оплаты консультаций',
    },
    component: () => import(/* webpackChunkName: "my" */ '../views/ConsultantListPage.vue'),
  },
  {
    path: '/insta',
    meta: {
      title: 'Сайт психолога Елены Сягло',
      description: 'Сайт психолога Елены Сягло',
    },
    name: 'Insta',
    component: () => import(/* webpackChunkName: "my" */ '../views/Insta.vue'),
  },
  /* Новый кабинет лендинг */
  {
    path: '/my',
    name: 'Cabinet',
    meta: {
      title: 'Личный кабинет',
      description: 'Добро пожаловать в Личный кабинет',
    },
    component: () => import(/* webpackChunkName: "my" */ '../views/Cabinet.vue'),
  },
  {
    path: '/cabinet',
    name: 'CabinetFull',
    meta: {
      title: 'Личный кабинет',
      description: 'Добро пожаловать в Личный кабинет',
    },
    component: () => import(/* webpackChunkName: "my" */ '../views/Cabinet.vue'),
  },
  {
    path: '/admin',
    name: 'Admin',
    meta: { title: 'Курс для женщин «Медитация в новую жизнь»' },
    component: () => import(/* webpackChunkName: "my" */ '../views/Admin.vue'),
  },
  {
    path: '/about',
    name: 'About',
    meta: { title: 'Курс для женщин «Медитация в новую жизнь»' },
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/auth',
    name: 'Auth',
    meta: {
      title: 'Войти в личный кабинет',
      description: 'Введите свой email и на почту придет короткий код подтверджения.',
    },
    component: () => import(/* webpackChunkName: "auth" */ '../views/Auth.vue'),
  },
  {
    path: '/restore',
    name: 'Restore',
    meta: { title: 'Курс для женщин «Медитация в новую жизнь»' },
    component: () => import(/* webpackChunkName: "auth" */ '../views/Auth.vue'),
  },
  {
    path: '/promo1',
    name: 'promo1',
    meta: { title: 'Курс для женщин «Медитация в новую жизнь»' },
    component: () => import(/* webpackChunkName: "faq" */ '../views/PromoPlayer.vue'),
  },
  {
    path: '/faq',
    name: 'FAQ',
    meta: { title: 'Курс для женщин «Медитация в новую жизнь»' },
    component: () => import(/* webpackChunkName: "faq" */ '../views/FAQ.vue'),
  },
  {
    path: '/agreement',
    name: 'Agreement',
    meta: { title: 'Курс для женщин «Медитация в новую жизнь»' },
    component: () => import(/* webpackChunkName: "agreement" */ '../views/Agreement.vue'),
  },
  /* Лнединг для покупки модулей с каунторм на 3 дня */
  {
    path: '/supermodule-arhive',
    name: 'Supermodule',
    meta: { title: 'Курс для женщин «Медитация в новую жизнь»' },
    component: () => import(/* webpackChunkName: "my" */ '../views/Supermodule.vue'),
  },
  {
    path: '/mnj-webinar-arhive',
    name: 'mnj-webinar',
    meta: { title: 'Курс для женщин «Медитация в новую жизнь»' },
    component: () => import(/* webpackChunkName: "my" */ '../views/WebinarRegistration.vue'),
  },
  {
    path: '/start-arhive',
    name: 'Start',
    meta: { title: 'Курс для женщин «Медитация в новую жизнь»' },
    component: Home,
  },
  /* Лендинг со скидкой с каунтером на сутки */
  {
    path: '/welcomeWebinar-arhive',
    name: 'WelcomeWebinar',
    meta: { title: 'Курс для женщин «Медитация в новую жизнь»' },
    component: () => import(/* webpackChunkName: "my" */ '../views/Welcome.vue'),
  },
  /* Обычный лендинг со скидкой, но скаунторм на 3 дня */
  {
    path: '/specialprice-arhive',
    name: 'WelcomeWebinar36',
    meta: { title: 'Курс для женщин «Медитация в новую жизнь»' },
    component: () => import(/* webpackChunkName: "my" */ '../views/Welcome.vue'),
  },
  /* Лендинг без скидки */
  {
    path: '/mnj-arhive',
    name: 'Home',
    meta: {
      title: 'Курс для беременности',
      description: 'Курс для беременности',
    },
    component: () => import(/* webpackChunkName: "my" */ '../views/Welcome.vue'),
  },
  /* Лендинг описание, без оплаты и акций */
  {
    path: '/courseInfo-arhive',
    name: 'CourseInfo',
    meta: { title: 'Курс для женщин «Медитация в новую жизнь»' },
    component: () => import(/* webpackChunkName: "my" */ '../views/Welcome.vue'),
  },
  {
    path: '/meditation-group-arhive/:id/:meditationId',
    name: 'MeditationGroup',
    meta: { title: 'Курс для женщин «Медитация в новую жизнь»' },
    component: () => import(/* webpackChunkName: "meditation" */ '../views/MeditationGroup.vue'),
  },
  {
    path: '*',
    name: 'MeditationGroup',
    meta: { title: 'Курс для женщин «Медитация в новую жизнь»' },
    component: () => import(/* webpackChunkName: "meditation" */ '../views/ErrorPage.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;

  if (to.meta.description) {
    document.querySelector('meta[name=description]')
      .setAttribute('content', to.meta.description);
  } else {
    document.querySelector('meta[name=description]')
      .setAttribute('content', 'Курс для женщин «Медитация в новую жизнь»');
  }

  next();
});

export default router;
