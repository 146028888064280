<template>
    <div class="contacts">
        <div class="es-dialog__text">
            ИП ГОСТЕВА ЕЛЕНА ВЯЧЕСЛАВОВНА,<br>
            ИНН 673110487432,<br>
            ОГРН 318673300023780, <br><br>
            РФ, 214018, Смоленская область, г. Смоленск, пер. Киевский, д.17, корп.1, кв.76,
            <br><br>
            e-mail - <a href="mailto:info@elena.shop">info@elena.shop</a>
        </div>
    </div>
</template>

<script>

export default {
  name: 'Contacts',
  data() {
    return {
    };
  },
  computed: {
    infoForMeditationsInCart() {
      return this.$store.getters.infoForMeditationsInCart;
    },
    cartList() {
      return this.$store.state.meditations.cartList;
    },
  },
  mounted() {

  },
  methods: {
    closeDialog() {
      this.$store.commit('hideDialog');
    },
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/dialog";

.cart-sale {
  margin: 20px 0 0;

    .es-dialog__text {
        margin-bottom: 30px;
        font-size: 12px;
        line-height: 24px;
        text-align: left;
        margin: 30px -20px;
    }
}
</style>
