<template>
    <div class="cases-info">
        <div class="cases-info__icon-cover">
            <div class="cases-info__icon">
                <div class="cases-info__text"></div>
            </div>
        </div>
        <div class="es-dialog__text" v-html="dialogData"></div>
    </div>
</template>

<script>

export default {
  name: 'CaseInfo',
  data() {
    return {
      complete: false,
    };
  },
  computed: {
    dialogData() {
      console.log(this.$store.state.dialog.casesContent);
      return this.$store.state.dialog.casesContent;
    },
  },
  methods: {

  },
};
</script>

<style lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/dialog";

.cases-info {
  margin: 40px 0 0;
    &__icon-cover {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    &__text {
        font-size: 14px;
        line-height: 16px;
        font-weight: 700;
        color: $white;
        transform: rotate(-45deg) translate(-5px, 16px);
        text-align: left;
    }

    b{
        font-weight: 600;
    }

    .es-dialog__text {
        text-align: left;
    }
}
</style>
