<template>
  <div id="app" :class="{'is1024Width': isCourseLanding, 'isFullWidth': isMarathon}">
    <router-view/>
    <BaseDialog></BaseDialog>
    <GlobalMessage></GlobalMessage>
  </div>
</template>

<script>
import axios from 'axios';
import smoothscroll from 'smoothscroll-polyfill';

import BaseDialog from './components/dialog/BaseDialog.vue';
import GlobalMessage from './components/general/GlobalMessage.vue';

export default {
  name: 'home',
  components: {
    BaseDialog, GlobalMessage,
  },
  mounted() {
    this.$store.commit('setDataFromLocalStorage');
    this.$store.commit('getUserMailFromLocalStorage');
    this.$store.commit('getUserAuthTokenFromLocalStorage');

    const url = new URL(window.location.href);
    const authToken = url.searchParams.get('authToken');

    this.sendFBPixelData();

    if (authToken) {
      // this.$router.push(window.location.pathname);
    }

    this.$store.commit('setUpdateMeditationSource', 'startPage');
    this.$store.dispatch('updateMeditations');

    this.getPromoInfo();
    smoothscroll.polyfill();

    /* Проверим нет ли кавычек в почте и если есть чистим */
    this.checkAndClearUserEmail();
    /* Проверим что почта валидная и если это не так, то деавторизуем пользователя */
    this.checkUserEmail();
    /* Проверим что у авторизованого пользователя есть аус токен, если нет то деаторизуем */
    this.checkAuthToken();
  },
  computed: {
    getUserMail() {
      return this.$store.getters.getUserMail;
    },
    getAuthToken() {
      return this.$store.getters.getAuthToken;
    },
    isCourseLanding() {
      return this.$route.name === 'CourseLanding' || this.$route.name === 'Courseland20' || this.$route.name === 'Courseland20Short';
    },
    isMarathon() {
      return this.$route.name === 'Marathon' || this.$route.name === 'Guidebook' || this.$route.name === 'MarathonHealing' || this.$route.name === 'MarathonHealingShort' || this.$route.name === 'MarathonHealingShort2';
    },
  },
  beforeMount() {
    this.$store.dispatch('getSiteSettings');
  },
  methods: {
    checkAuthToken() {
      if (this.getUserMail !== '' && this.getAuthToken === '') {
        this.$store.dispatch('logout');
      }
    },
    checkUserEmail() {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const isMailValid = re.test(String(this.getUserMail).toLowerCase());

      if (!isMailValid) {
        this.$store.dispatch('logout');
      }
    },
    checkAndClearUserEmail() {
      const hasQuotes = this.getUserMail.includes('"');

      if (hasQuotes) {
        const updatedEmail = this.getUserMail.replaceAll('"', '');
        this.$store.commit('saveUserMail', updatedEmail);
        this.$store.commit('getUserMailFromLocalStorage');
      }
    },
    sendFBPixelData() {
      /* Не понятно по какой причине,
      но в FB уходят события с почтой, исключаем их наверняка */
      const urlParam = new URL(window.location.href).searchParams.get('email');
      const hasEmailInParams = window.location.search.search('email') > 0;
      const isTransitPage = window.location.pathname.includes('/mnj-webvisitors');

      if (typeof window.fbq !== 'undefined' && !urlParam && !hasEmailInParams && !isTransitPage) {
        window.fbq('track', 'PageView');
      }
    },
    getPromoInfo() {
      if (this.getUserMail === '') {
        return false;
      }

      const currentUrl = this.$store.getters.getLocalUrl;

      axios
        .post(`${currentUrl}/v2/api/getUserActivePromo.php`, {
          mail: this.getUserMail,
        })
        .then((response) => {
          if (response.data.success) {
            const promoData = {
              hasActivePromo: response.data.hasActivePromo,
              userPromoCode: response.data.userPromoCode,
              validDate: response.data.validDate,
              isUsed: response.data.isUsed,
              discount: response.data.discount,
            };

            this.$store.commit('setUserPromoData', promoData);
          }
        });

      return true;
    },
  },
};
</script>

<style lang="scss">
@import "assets/scss/common/reset";
@import "assets/scss/common/variables";

:root {
  --main-bg-color: $white;
  --main-color: $main-pink;
  --base-url: '/';
  --custom-vh:  0;
}

body {
  background:  var(--main-bg-color);
}

body, html {
  overflow-x: hidden;
}

html.playerIsActive {
  overflow: hidden;

  body {
    overflow: hidden;
  }
}

#app {
  font-family: 'Montserrat', sans-serif;
  width: 100%;
  min-width: $mobile-min;
  max-width: $mobile-max;
  margin: auto;
  color: $black;

  &.is1024Width {
    @media only screen and (min-width: 1024px) {
      max-width: 1024px;
    }
  }

  &.isFullWidth {
    max-width: none;
  }
}
</style>
