<template>
    <div class="success-message">
        <div class="es-dialog__icon-cover">
            <div class="es-dialog__icon"
                 :style="`background-image: url('${publicPath}images/success-icon.svg')`"
            >
            </div>
        </div>

        <div class="es-dialog__text" v-if="isRestoreMode">
            Если ваш емэйл написан с ошибкой,
            сообщите об этом в директ Инстаграм @elena.siaglo,
            и наши менеджеры помогут исправить
        </div>

        <div class="es-dialog__text" v-else>
            Оплата {{textForMessage}} прошла успешно. <br><br>

            Ваш email для доступа к Личному кабинету - <strong>{{userMail}}</strong>
        </div>

        <template v-if="isRestoreMode">
            <button type="button" @click="closeDialog" class="es-button es-button--blue">
                Хорошо
            </button>
            <button type="button" @click="hideRestoreRules()" class="es-button es-button--link">
                Назад
            </button>
        </template>
        <template v-else>
            <button type="button" @click="closeDialog" class="es-button es-button--blue">
                Подтвердить email
            </button>
            <button type="button" @click="showRestoreRules()" class="es-button es-button--link">
                Не согласен
            </button>
        </template>

        <div class="es-dialog__text" v-if="coursePaymentType === 'premium'">
            Напишите в директ Инстаграм и согласуйте
            с менеджером даты проведения консультаций с психологом
        </div>

    </div>
</template>

<script>

export default {
  name: 'SaleInfo',
  data() {
    return {
      complete: false,
      publicPath: process.env.BASE_URL,
      isRestoreMode: false,
    };
  },
  computed: {
    userMail() {
      return this.$store.getters.getUserMail;
    },
    lastPayedCount() {
      return this.$store.state.meditations.lastPayedCount;
    },
    textForMessage() {
      return this.lastPayedCount > 1 ? 'курса' : 'товара';
    },
    coursePaymentType() {
      return this.$store.state.payment.landingPaymentType;
    },
  },
  methods: {
    closeDialog() {
      this.$store.commit('hideDialog');
    },
    showRestoreRules() {
      this.isRestoreMode = true;
    },
    hideRestoreRules() {
      this.isRestoreMode = false;
    },
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/dialog";

.success-message {
  margin: 40px 0 0;

    .es-dialog__text {
        margin-bottom: 30px;
        font-size: 13px;

        strong {
            font-weight: 600;
        }
    }
}
</style>
