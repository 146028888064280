<template>
    <div class="main-slider">
        <div class="glide">
            <div data-glide-el="track" class="glide__track">
                <ul class="glide__slides">
                    <li class="glide__slide" v-for="(slide, index) in sliderData" :key="index">
                        <div class="glide__prevent-click-cover"></div>

                        <router-link
                                :to="returnUrl(index)"
                        >
                            <div class="glide__slide-img">
                                <div class="main-slider__sale-label"
                                     v-if="!isAllPayed(index) && slide.saleForAll !== 0"
                                >
                                    {{slide.saleForAll}}%
                                </div>
                                <img :src="`${publicPath}${slide.mainSliderImage}`">
                            </div>
                        </router-link>
                    </li>
                </ul>
            </div>

            <router-link :to="returnUrl(currentSlide)"
                         class="main-slider__descr"
                         v-html="getSliderDescr"
            ></router-link>

            <div class="main-slider__nav" data-glide-el="controls">
                <button class="glide__arrow glide__arrow--left" data-glide-dir="<"></button>

                <div class="main-slider__nav-bullets glide__bullets" data-glide-el="controls[nav]">
                    <button class="main-slider__nav-dot" data-glide-dir="=0"></button>
                    <button class="main-slider__nav-dot" data-glide-dir="=1"></button>
                    <button class="main-slider__nav-dot" data-glide-dir="=2"></button>
                </div>

                <button class="glide__arrow glide__arrow--right" data-glide-dir=">"></button>
            </div>
        </div>
    </div>
</template>

<script>
import Glide, { Controls, Swipe, Breakpoints } from '@glidejs/glide/dist/glide.modular.esm';

export default {
  name: 'BaseMainSlider',
  props: {
    msg: String,
  },
  components: {},
  data() {
    return {
      publicPath: process.env.BASE_URL,
      glide: false,
      isMoving: false,
      startMousePosition: 0,
      moveDiff: 0,
      xPos: 0,
      xPosMove: 0,
      currentSlide: 0,
      startSlide: 0,
      nextSlideVisibility: 100,
    };
  },
  mounted() {
    this.sliderData.forEach((item, index) => {
      if (item.groupName === this.$route.params.slide) {
        this.startSlide = index;
        this.currentSlide = index;
      }
    });

    if (window.innerHeight > 667) {
      this.nextSlideVisibility = 70;
    }

    /* Костяль для инстабраузера, после загрузки еще раз проврим высоту экрана */
    setTimeout(() => {
      if (window.innerHeight > 667) {
        this.nextSlideVisibility = 70;
      } else {
        this.nextSlideVisibility = 100;
      }

      this.glide.update({
        peek: { before: 30, after: this.nextSlideVisibility },
      });
    }, 500);

    this.glide = new Glide('.glide', {
      type: 'carousel',
      perView: 1,
      gap: 0,
      peek: { before: 30, after: this.nextSlideVisibility },
      bound: false,
      swipeThreshold: 2,
      dragThreshold: 2,
      animationDuration: 300,
      startAt: this.startSlide,
      breakpoints: {
        480: {
          perView: 1,
          focusAt: 0,
          startAt: 0,
        },
        768: {
          perView: 2,
          focusAt: 'center',
          startAt: 1,
          type: 'carousel',
        },
        1920: {
          perView: 2,
          focusAt: 'center',
          startAt: 1,
          type: 'slider',
        },
      },
    }).mount({ Controls, Swipe, Breakpoints });

    document.addEventListener('touchstart', (e) => {
      this.xPos = e.touches[0].clientX;
    }, true);

    document.addEventListener('touchmove', (e) => {
      this.xPosMove = e.touches[0].clientX;
    }, true);

    this.glide.on(['swipe.start', 'run'], () => {
      //      this.isMoving = true;
      // console.log(`strat: ${this.glide.index}`);
      // console.log(`stratPos: ${this.xPos}`);
    });

    this.glide.on(['move'], () => {
      // console.log(`Mooooving: ${this.xPos}`);
    });

    this.glide.on(['swipe.move', 'run'], () => {
      const moveDiff = this.xPosMove - this.xPos;
      const slides = document.querySelectorAll('.glide__slide .glide__slide-img');
      const nextSlide = moveDiff > 0 ? this.currentSlide - 1 : this.currentSlide + 1;
      const imgWitdh = 275;
      const finalScaleNext = 0.85 + Math.abs(((moveDiff / imgWitdh) / 7));
      const finalScaleCurrent = 1 - Math.abs(((moveDiff / imgWitdh) / 7));

      if (slides[nextSlide]) {
        slides[nextSlide].style.transform = `scale(${finalScaleNext})`;
      }

      slides[this.currentSlide].style.transform = `scale(${finalScaleCurrent})`;
    });

    this.glide.on(['swipe.end', 'run'], () => {
      const slides = document.querySelectorAll('.glide__slide .glide__slide-img');
      this.currentSlide = this.glide.index;

      slides.forEach((item, index) => {
        slides[index].style.removeProperty('transform');
      });
    });
  },
  computed: {
    sliderData() {
      return this.$store.state.meditations.meditationsList;
    },
    getSliderDescr() {
      let descrData = '';

      this.sliderData.forEach((item, index) => {
        if (index === this.currentSlide) {
          descrData = item.mainSliderDescr;
        }
      });

      return descrData;
    },
  },
  methods: {
    returnUrl(index) {
      const group = this.sliderData[index];

      return `/meditation-group/${group.groupName}/${group.list[0].url}`;
    },
    isAllPayed(index) {
      let isAllPayed = true;

      this.sliderData[index].list.forEach((item) => {
        if (!item.isPayed) {
          isAllPayed = false;
        }
      });

      return isAllPayed;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../assets/scss/common/glide";
@import "../assets/scss/common/variables";

.main-slider {
    display: flex;
    padding-top: 10px;
    box-sizing: border-box;

    &__sale-label {
        background: url('/images/sale-label.svg') no-repeat;
        width: 45px;
        height: 55px;
        background-size: contain;
        line-height: 50px;
        text-align: center;
        font-size: 12px;
        color: $white;
        position: absolute;
        right: 12%;
        top: -3px;
        z-index: 10;
        transition: all .3s cubic-bezier(.54,.01,.1,1);
        transform: translateZ(0);
        font-weight: 600;

        @media only screen and (max-width: 374px) {
            right: 25%;
        }
    }

    &__descr {
        line-height: 22px;
        height: 66px;
        overflow: hidden;
        font-size: 14px;
        margin: 16px auto 30px auto;
        padding: 0 24px;
        font-family: $textFont;
        color: $black;
        width: 320px;
        display: block;
        text-decoration: none;
        cursor: pointer;

        /*iphone 6 и другие с хорошой шириной экрана но высотой меньше чем у iphone 11*/
        @media only screen and (max-height: 700px) and (min-width: 374px) {
            margin: 0 0 20px 0;
        }

        /*iphone 5*/
        @media only screen and (max-width: 374px) {
            font-size: 13px;
            line-height: 18px;
            margin: 0 0 10px 0;
        }

        /* ipad */
        @media only screen and (min-width: 768px) {
            margin: 16px auto 30px auto;
            font-size: 18px;
            line-height: 26px;
            height: 78px;
            width: 380px;
        }
    }

    &__nav {
        display: flex;
        justify-content: center;
        align-items: center;

        @media only screen and (min-width: 768px) {
            margin-bottom: 5px;
        }

        .glide__arrow {
            background: url('/images/right-arrow.svg') no-repeat 50% 50% $main-blue;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            margin: 0 10px;
            border: 0;
            position: relative;
            top: 1px;
            flex-shrink: 0;
            cursor: pointer;

            &--right {
                transform: rotate(180deg);
            }

            @media only screen and (max-width: 768px) {
               display: none;
            }
        }
    }

    &__nav-dot {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin: 0 2px;
        background: rgba($main-blue, 0.1);
        border: 5px solid $white;
        padding: 0;
        box-sizing: unset;

        &.glide__bullet--active {
            background: rgba($main-blue, 1);
        }
    }
}

.glide {
    &__prevent-click-cover {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 10;

        @media only screen and (min-width: 768px) {
            cursor: default;
        }
    }

    &__slides {
        position: relative;
        left: -16px;
        padding-bottom: 20px;
    }

    &__slide-img {
        position: relative;
        background-size: contain;
        background-repeat: no-repeat;
        border-radius: 10px;
        transition: transform .3s cubic-bezier(.54,.01,.1,1),
                    box-shadow .3s cubic-bezier(.54,.01,.1,1);
        transform: scale(0.88);
    }

    &__slide {
        width: calc(100vw - 80px);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 24px;
        position: relative;
        cursor: pointer;

        a {
            display: block;
            width: 100%;
            cursor: pointer;
        }

        img {
            max-width: 100%;
            /*max-height: calc(100vh - 280px);*/
            border-radius: 24px;
            display: block;
            transition: transform .3s cubic-bezier(.54,.01,.1,1),
                        box-shadow .3s cubic-bezier(.54,.01,.1,1);
            box-shadow: 0 14px 14px rgba(0, 0, 0, 0.05);
            user-select: none;
            user-drag: none;
            cursor: default;

            /*!*юольшие экраны*!*/
            /*@media only screen and (min-width: 468px) {*/
                /*max-height: calc(100vh - 300px);*/
            /*}*/

            /*!*iphone 5*!*/
            /*@media only screen and (max-width: 374px) {*/
                /*max-height: calc(100vh - 280px);*/
            /*}*/

            /*!*iphone 6 и другие с хорошой шириной экрана но высотой меньше чем у iphone 11*!*/
            /*@media only screen and (max-height: 700px) and (min-width: 374px) {*/
                /*max-height: calc(100vh - 280px);*/
            /*}*/

            /*iphone 6 и другие с хорошой шириной экрана но
            высотой меньше чем у iphone 11 фикс для instagram
            */
            /*@media only screen and (min-width: 374px) and (max-height: 600px) {*/
                /*max-height: calc(100vh - 210px);*/
            /*}*/
        }

        &--active {
            // убираем обрезание тени для первого слайда
            &:first-child {
                img {
                    box-shadow: 3px 14px 14px rgba(0, 0, 0, 0.15);
                }
            }

            .glide__slide-img {
                transform: scale(0.95);
            }

            img {
                box-shadow: 0 14px 14px rgba(0, 0, 0, 0.15);

                @media only screen and (min-width: 768px) {
                    cursor: pointer;
                }
            }

            .glide__prevent-click-cover {
                display: none;
            }
        }
    }
}

</style>
