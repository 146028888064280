import axios from 'axios';

export default ({
  state: {
    isFullPackageSelected: true,
    isOpened: false,
    isOpenedGetCourse: false,
    priceToPay: 0,
    paymentCarrency: 'USD',
    priceWithoutDiscount: 0,
    isYaPayVisible: false,
    isStripePayVisible: false,
    cardErrorText: '',
    promoDiscount: 0,
    promoDiscountValue: 0,
    promoDiscountType: '',
    activatedPromoCode: null,
    isPromoNotActivated: false,
    localPromoError: false,
    minTotalForPromo: 0,
    isLandingPageCoursePayment: false,
    isModulePagePayment: false,
    isNewMeditationPagePayment: false,
    isCartMeditationPagePayment: false,
    isSinglePagePayment: false,
    isConsultationPayment: false,
    isModulePaymentFull: true,
    landingPaymentType: 'base',
    landingPaymentPriceToPay: 0,
    landingPaymentSavings: 0,
    landingPaymentFullPrice: 0,
    currentPaymentModule: false,
    webinarPaymentData: {},
  },
  mutations: {
    setWebinarPaymentData(state, data) {
      state.webinarPaymentData = data;
    },
    setCurrentPaymentModule(state, data) {
      state.currentPaymentModule = data;
    },
    setLandingPaymentType(state, data) {
      state.landingPaymentType = data;
    },
    setLandingPaymentPriceToPay(state, data) {
      state.landingPaymentPriceToPay = data;
    },
    setLandingPaymentSavings(state, data) {
      state.landingPaymentSavings = data;
    },
    setLandingPaymentFullPrice(state, data) {
      state.landingPaymentFullPrice = data;
    },
    setLandingPagePayment(state, data) {
      state.isLandingPageCoursePayment = data;
    },
    setSinglePagePayment(state, data) {
      state.isSinglePagePayment = data;
    },
    setConsultationPayment(state, data) {
      state.isConsultationPayment = data;
    },
    setModulePagePayment(state, data) {
      state.isModulePagePayment = data;
    },
    setNewMeditationPagePayment(state, data) {
      state.isNewMeditationPagePayment = data;
    },
    setCartMeditationPagePayment(state, data) {
      state.isCartMeditationPagePayment = data;
    },
    clearAllPaymentFlags(state) {
      state.isModulePagePayment = false;
      state.isConsultationPayment = false;
      state.isSinglePagePayment = false;
      state.isLandingPageCoursePayment = false;
    },
    setModuleLenght(state, data) {
      state.isModulePaymentFull = data;
    },
    changeMinTotalForPromo(state, data) {
      state.minTotalForPromo = data;
    },
    setLocalPromoError(state, data) {
      state.localPromoError = data;
    },
    setPriceWithoutDiscount(state, data) {
      state.priceWithoutDiscount = data;
    },
    setPromoDiscount(state, data) {
      state.promoDiscount = data;
    },
    setPromoDiscountValue(state, data) {
      state.promoDiscountValue = data;
    },
    setPromoDiscountType(state, data) {
      state.promoDiscountType = data;
    },
    setPromoWrongActivationStatus(state, data) {
      state.isPromoNotActivated = data;
    },
    setPromoCode(state, data) {
      state.activatedPromoCode = data;
    },
    setCardErrorText(state, data) {
      state.cardErrorText = data;
    },
    setPriceToPay(state, data) {
      state.priceToPay = data;
    },
    setPaymentCarrency(state, data) {
      state.paymentCarrency = data;
    },
    setPackagesSwithcer(state, data) {
      state.isFullPackageSelected = data;
    },
    showPayment(state) {
      state.isOpened = true;
    },
    showPaymentGetCourse(state) {
      state.isOpenedGetCourse = true;
    },
    hideYaPay(state) {
      state.isYaPayVisible = false;
    },
    hideStripePay(state) {
      state.isStripePayVisible = false;
    },
    showYaPay(state) {
      state.isYaPayVisible = true;
    },
    showStripePay(state) {
      state.isStripePayVisible = true;
    },
  },
  actions: {
    hidePayment({ state, commit, getters }) {
      const { isGetCourse } = getters;

      state.isOpened = false;
      state.isOpenedGetCourse = false;
      state.isFullPackageSelected = true;
      state.localPromoError = false;
      state.promoDiscount = 0;
      state.activatedPromoCode = null;
      state.landingPaymentType = 'base';
      state.paymentCarrency = 'USD';
      state.isPromoNotActivated = false;
      state.isCartMeditationPagePayment = false;
      state.isLandingPageCoursePayment = false;
      commit('markAllMeditationNotForPay');
      if (isGetCourse) {
        commit('clearCart');
      }
    },
    addUserToWebinar({ commit, getters }) {
      const currentUrl = getters.getLocalUrl;

      axios
        .get(`${currentUrl}/index.php?route=api/meditation/addUserToWebinar/`, {
          params: {
            name: getters.webinarPaymentData.userName,
            mail: getters.webinarPaymentData.userEmail,
            webinarID: getters.flowWebinarZoomID,
          },
        })
        .then((response) => {
          if (response.data.success) {
            commit('showGlobalMessage', `Оплата прошла успешно. Данные отправленны на почту ${getters.webinarPaymentData.userEmail}`);
          } else {
            commit('showGlobalError', 'Произошла ошибка, обратитесь к менеджеру.');
          }
        });
    },
    saveTelegramUser({ commit, getters }) {
      axios
        .post(`${getters.getLocalUrl}/v2/api/saveTelegramUser.php`, {
          phone: getters.webinarPaymentData.userPhone,
          telegram: getters.webinarPaymentData.userTelegram,
        })
        .then((response) => {
          if (response.data.success) {
            commit('showGlobalMessage', 'Оплата прошла успешно. В ближайшее время администратор добавит вас в группу.');
          } else {
            commit('showGlobalError', 'Произошла ошибка, обратитесь к менеджеру.');
          }
        });
    },
    showSuccessResult({ commit, getters }) {
      if (getters.getUserMail === '') {
        commit('setCurrentTemplate', 'saveData');
      } else {
        commit('setCurrentTemplate', 'successMessage');
      }

      commit('showDialog');
      commit('setPromoDiscount', 0);
    },
    updateMeditations({ commit, getters, dispatch }) {
      if (getters.getUserMail === '') {
        return false;
      }

      if (getters.getAuthToken === '') {
        dispatch('logout');
      }

      axios
        .post(`${getters.getLocalUrl}/v2/api/restoreDataFromCabinet.php`, {
          mail: getters.getUserMail,
          token: getters.getAuthToken,
        })
        .then((response) => {
          if (response.data.success) {
            let userData = JSON.parse(localStorage.getItem('meditationAudio'));
            let tempArray = [];
            let resultPackages = [];
            const result = [];

            resultPackages = response.data.packages.split(',');
            tempArray = response.data.purchases.split(',');

            if (parseInt(response.data.consultationCount, 10) > 0) {
              commit('setConsultationCount', parseInt(response.data.consultationCount, 10));
            }

            /* eslint-disable */
            for (const str of tempArray) {
              if (str.toString().includes('wb')) {
                if (!result.includes(str)) {
                  result.push(str.toString());
                }
              } else if (!result.includes(parseInt(str, 10))) {
                result.push(parseInt(str, 10));
              }
            }
            /* eslint-disable */

            if (userData) {
              userData.payed = result;
              userData.payedPackages = resultPackages;
            } else {
              userData = {
                payed: result,
                payedPackages: resultPackages,
              };
            }

            localStorage.setItem('meditationAudio', JSON.stringify(userData));
            commit('setDataFromLocalStorage');

            if (getters.getMeditationSource !== 'startPage') {
              commit('showDialogByType', 'cabinet-update');
            }
          } else {
            if (response.data.authError) {
              commit('showGlobalError', response.data.errorText);
              dispatch('logout');
              window.location.href = '/auth?logouted=true';
            }

            commit('showGlobalError', 'Не удалось обновить данные');
          }
        });

      return true;
    },
  },
  modules: {
  },
  getters: {
    getIsFullPackageSelected: (state) => state.isFullPackageSelected,
    getPriceToPay: (state) => state.priceToPay,
    isLandingPageCoursePayment: (state) => state.isLandingPageCoursePayment,
    coursePaymentType: (state) => state.landingPaymentType,
    webinarPaymentData: (state) => state.webinarPaymentData,
  },
});
