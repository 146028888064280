<template>
    <div class="reviews">
        <div class="glide">
            <div data-glide-el="track" class="glide__track">
                <ul class="glide__slides">
                    <li class="glide__slide"
                        v-for="(item, index) in currentMeditation.reviews"
                        :key="index"
                    >
                        <img :src="item">
                    </li>
                </ul>
            </div>

            <div class="reviews__nav" data-glide-el="controls">
                <button class="reviews__arrow" data-glide-dir="<"></button>
                <div class="reviews__nav-dots" data-glide-el="controls[nav]">
                    <button class="reviews__nav-dot"
                            v-for="(item, index) in currentMeditation.reviews"
                            :key="index"
                            :data-glide-dir="'=' + index"
                    ></button>
                </div>
                <button class="reviews__arrow forward" data-glide-dir=">"></button>
            </div>
        </div>
    </div>
</template>

<script>
import Glide, { Controls, Swipe } from '@glidejs/glide/dist/glide.modular.esm';

export default {
  name: 'Reviews',
  components: {},
  data() {
    return {
      glide: '',
    };
  },
  computed: {
    currentMeditation() {
      console.log(this.$store.state.meditations.currentMeditation);
      return this.$store.state.meditations.currentMeditation;
    },
  },
  methods: {
    initGlide() {
      this.glide = new Glide('.glide', {
        type: 'slider',
        perView: 1,
        gap: 0,
        bound: false,
        swipeThreshold: 2,
        dragThreshold: 2,
        animationDuration: 300,
      }).mount({ Controls, Swipe });
    },
  },
  mounted() {
    this.initGlide();
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/dialog";
 @import "../../../assets/scss/common/glide";

.reviews {
    margin: 40px -24px 0 -24px;

    @media only screen and (min-width: 768px) {
        padding-top: 15px;
    }

    .glide__slides {
        img {
            max-width: 100%;
        }
    }

    .reviews__nav {
        margin: 24px 14px 0 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 40px;
    }

    .reviews__arrow {
        background: url('/images/right-arrow.svg') no-repeat 50% 50% $main-blue;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        margin: 0 10px;
        border: 0;
        position: relative;
        top: 1px;
        flex-shrink: 0;
        cursor: pointer;

        @media only screen and (min-width: 768px) {
            width: 40px;
            height: 40px;
            margin: 0 20px;
            background-size: 20%;
        }

        &.forward {
            transform: rotate(180deg);
        }
    }

    .reviews__nav-dots {
        display: flex;
    }

    .reviews__nav-dot {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: rgba($main-blue, 0.2);
        margin: 0 2px;
        border: solid 4px $white;
        padding: 0;
        box-sizing: content-box;

        &.glide__bullet--active {
            background: $main-blue;
        }
    }
}
</style>
