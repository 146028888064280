export default ({
  state: {
    isVisible: false,
    playerType: 'audio',
  },
  mutations: {
    showPlayer(state, type) {
      state.isVisible = true;
      document.querySelector('html').classList.add('playerIsActive');

      if (type === 'webinar') {
        state.playerType = 'video';
      }
    },
    hidePlayer(state) {
      state.isVisible = false;
      state.playerType = 'audio';
      document.querySelector('html').classList.remove('playerIsActive');
    },
  },
  actions: {
  },
  modules: {
  },
  getters: {

  },
});
