<template>
    <div class="base-dialog" :class="{'opened': isVisible}">
        <div class="base-dialog__cover"></div>
        <div class="base-dialog__content">
            <div class="base-dialog__close-btn"
                 @click="hideDialog"
                 v-if="
                 currentTemplate !== 'successMessage' &&
                 currentTemplate !== 'saveData' &&
                 currentTemplate !== 'cart-sale'
                "
            ></div>
            <featureNotIncluded
                    v-if="currentTemplate === 'features-meditation'"
            ></featureNotIncluded>
            <MeditationList
                    v-if="currentTemplate === 'meditation-list'"
            ></MeditationList>
            <WebinarList
                    v-if="currentTemplate === 'webinar-list'"
            ></WebinarList>
            <SaleInfo
                    v-if="currentTemplate === 'sale-info'"
            ></SaleInfo>
            <TelegramAccess
                    v-if="currentTemplate === 'telegram-access'"
            ></TelegramAccess>
            <Reviews
                    v-if="currentTemplate === 'review' && isVisible"
            ></Reviews>
            <Auth
                    v-if="currentTemplate === 'auth' && isVisible"
            ></Auth>
            <Auth
                    v-if="currentTemplate === 'saveData' && isVisible"
                    type="saveData"
            ></Auth>
            <HowToListen
                    v-if="currentTemplate === 'listen' && isVisible"
            ></HowToListen>
            <SuccessMessage
                    v-if="currentTemplate === 'successMessage' && isVisible"
            ></SuccessMessage>
            <UnlimitedAccess
                    v-if="currentTemplate === 'unlimited-access' && isVisible"
            ></UnlimitedAccess>
            <PriceDescription
                    v-if="currentTemplate === 'price-descr' && isVisible"
            ></PriceDescription>
            <PromoCongrats
                    v-if="currentTemplate === 'promo-congrats' && isVisible"
            ></PromoCongrats>
            <CabinetUpdateSuccess
                    v-if="currentTemplate === 'cabinet-update' && isVisible"
            ></CabinetUpdateSuccess>
            <ModulesList
                    v-if="currentTemplate === 'modules-list' && isVisible"
            ></ModulesList>
            <WebinarInvite
                    v-if="currentTemplate === 'webinar-invite' && isVisible"
            ></WebinarInvite>
            <WebinarParticipant
                    v-if="currentTemplate === 'webinar-participant' && isVisible"
            ></WebinarParticipant>
            <CartSale
                    v-if="currentTemplate === 'cart-sale' && isVisible"
            ></CartSale>
            <WebinarRegistration
                    v-if="currentTemplate === 'webinar-registration' && isVisible"
            ></WebinarRegistration>
            <Contacts
                    v-if="currentTemplate === 'contacts-footer' && isVisible"
            ></Contacts>
            <GetCourseFormSilver
                    v-if="currentTemplate === 'get-course-form-silver' && isVisible"
            ></GetCourseFormSilver>
            <GetCourseFormGold
                    v-if="currentTemplate === 'get-course-form-gold' && isVisible"
            ></GetCourseFormGold>
            <GetCourseFormDiamond
                    v-if="currentTemplate === 'get-course-form-diamond' && isVisible"
            ></GetCourseFormDiamond>
            <GetCourseFormDiamond2
                    v-if="currentTemplate === 'get-course-form-diamond2' && isVisible"
            ></GetCourseFormDiamond2>
            <SayThanks
                    v-if="currentTemplate === 'say-thanks-form' && isVisible"
            ></SayThanks>
            <CasesInfo
                    v-if="currentTemplate === 'casesInfo' && isVisible"
            ></CasesInfo>
        </div>
    </div>
</template>

<script>
import featureNotIncluded from './templates/FeatureNotIncluded.vue';
import MeditationList from './templates/MeditationList.vue';
import WebinarList from './templates/WebinarList.vue';
import SaleInfo from './templates/SaleInfo.vue';
import TelegramAccess from './templates/TelegramAccess.vue';
import Reviews from './templates/Reviews.vue';
import Auth from './templates/Auth.vue';
import HowToListen from './templates/HowToListen.vue';
import SuccessMessage from './templates/SuccessMessage.vue';
import UnlimitedAccess from './templates/UnlimitedAccess.vue';
import PriceDescription from './templates/PriceDescription.vue';
import PromoCongrats from './templates/PromoCongrats.vue';
import CabinetUpdateSuccess from './templates/CabinetUpdateSuccess.vue';
import ModulesList from './templates/ModulesList.vue';
import WebinarInvite from './templates/WebinarInvite.vue';
import WebinarParticipant from './templates/WebinarParticipant.vue';
import CartSale from './templates/CartSale.vue';
import Contacts from './templates/Contacts.vue';
import WebinarRegistration from './templates/WebinarRegistration.vue';
import GetCourseFormSilver from './templates/GetCourseFormSilver.vue';
import GetCourseFormGold from './templates/GetCourseFormGold.vue';
import GetCourseFormDiamond2 from './templates/GetCourseFormDiamond2.vue';
import GetCourseFormDiamond from './templates/GetCourseFormDiamond.vue';
import SayThanks from './templates/SayThanks.vue';
import CasesInfo from './templates/CasesInfo.vue';

export default {
  name: 'BaseDialog',
  props: {
    msg: String,
  },
  components: {
    featureNotIncluded,
    MeditationList,
    WebinarList,
    SaleInfo,
    TelegramAccess,
    Reviews,
    Auth,
    HowToListen,
    SuccessMessage,
    UnlimitedAccess,
    PriceDescription,
    PromoCongrats,
    CabinetUpdateSuccess,
    ModulesList,
    WebinarInvite,
    WebinarParticipant,
    CartSale,
    WebinarRegistration,
    GetCourseFormSilver,
    GetCourseFormGold,
    GetCourseFormDiamond,
    GetCourseFormDiamond2,
    Contacts,
    CasesInfo,
    SayThanks,
  },
  data() {
    return {
      complete: false,
    };
  },
  computed: {
    currentTemplate() {
      return this.$store.state.dialog.currentTemplate;
    },
    isVisible() {
      return this.$store.state.dialog.isVisible;
    },
  },
  methods: {
    hideDialog() {
      this.$store.commit('hideDialog');
    },
  },
};
</script>

<style scoped lang="scss">
 @import "../../assets/scss/common/variables";

.base-dialog {
    margin: 40px 0 0;
    z-index: 40;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top:0;
    width: 100%;
    height: 100vh;
    visibility: hidden;

    &__cover {
        background: rgba(0,0,0,0.8);
        position: fixed;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        opacity: 0;
        visibility: hidden;
        transition: opacity 100ms;
        top: 100%;
    }

    &__content {
        background: $white;
        border-radius: 10px;
        position: relative;
        min-height: 40px;
        padding: 24px;
        z-index: 40;
        width: 320px;
        transition: all 0ms;
        transform: translate(0, 100vh);
        max-width: calc(100vw - 46px);
        box-sizing: border-box;

        @media only screen and (min-width: 768px) {
            width: 460px;
        }
    }

    &.opened {
        visibility: visible;

        .base-dialog__content {
            transition: all 200ms;
            transform: translate(0, -40px);
        }

        .base-dialog__cover {
            visibility: visible;
            transition: opacity 200ms;
            opacity: 1;
            top: 0;
        }
    }

    &__close-btn {
        width: 24px;
        height: 24px;
        background: $main-blue;
        border-radius: 50%;
        cursor: pointer;
        position: absolute;
        right: 24px;

        @media only screen and (min-width: 768px) {
            width: 30px;
            height: 30px;
        }

        &:before {
            width: 100%;
            height: 100%;
            display: flex;
            background: url("/images/close-icon-white.svg") no-repeat 50% 50%;
            content: '';
            background-size: 8px;

            @media only screen and (min-width: 768px) {
                background-size: 10px;
            }
        }
    }
}
</style>
